<template>
  <a href="https://justworks.com" style="display: flex" aria-label="Go to homepage">
    <svg data-v-2ca3167c="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.999 24.074" width="130">
      <path fill="#52b0ff" d="M129.264 18.651H.7v5.423h128.564Z"></path>
      <path
        fill="currentColor"
        d="m0 13.191 4.531-.86c.087 2.321.817 3.442 2.352 3.442 1.331 0 1.95-.619 1.95-2.408V0h4.717v12.244c0 4.791-1.981 6.766-6.766 6.766-4.958 0-6.406-2.897-6.784-5.819M16.472 14.095V5.107h4.6v9.075c0 1.306.619 1.82 1.622 1.82 1.331 0 2.136-.817 2.136-2.711V5.107h4.6v13.55h-4.389v-3.658h-.118c-.675 3.169-2.167 3.987-4.2 3.987-2.854-.012-4.259-1.517-4.259-4.89ZM31.786 15.773l2.482-1.677a3.91 3.91 0 0 0 3.8 2.1c1.275 0 1.832-.371 1.832-1.089 0-.817-.544-1.25-2.067-1.523l-1.894-.359c-2.55-.458-3.931-1.95-3.931-4.042 0-2.8 2.253-4.414 5.689-4.414 3.41 0 5.02 1.405 5.88 2.984L41.169 9.3a3.1 3.1 0 0 0-3.009-1.863c-1.188 0-1.82.427-1.82 1.133 0 .675.514 1.059 1.82 1.306l1.919.359c2.841.514 4.229 1.678 4.229 4.2 0 2.377-1.523 4.531-5.993 4.531-3.3 0-5.534-1.263-6.537-3.2ZM47.051 14.999V7.818h-1.832V5.107h1.832V1.554h4.6v3.553h2.222v2.711h-2.222v6.766c0 .891.359 1.244 1.034 1.244h1.188v2.826h-3.008c-2.9 0-3.813-.916-3.813-3.658ZM55.329 5.107h4.989l1.863 8.53h.111l2.383-8.53h3.8l2.291 8.53h.117l2.062-8.53h3.628l-4.2 13.544h-3.962l-2.408-8.518h-.11l-2.3 8.518h-3.956ZM77.005 11.873c0-4.327 2.823-7.094 7.038-7.094s7.039 2.767 7.039 7.094-2.823 7.094-7.039 7.094-7.038-2.755-7.038-7.094m9.261 0c0-3.5-.762-4.414-2.223-4.414s-2.222.916-2.222 4.414.761 4.414 2.222 4.414 2.224-.917 2.224-4.414ZM93.118 5.107h4.327v4.5h.118c.643-3.714 1.894-4.587 3.9-4.587h.241v4.055h-2.222c-1.331 0-1.765.427-1.765 2.037v7.527h-4.6ZM109.381 13.231l-1.324 1.461v3.959H103.5V.031h4.557v10.895l4.705-5.819h4.1l-4.469 4.872 4.76 8.672h-4.879ZM117.447 15.773l2.492-1.677a3.92 3.92 0 0 0 3.8 2.1c1.275 0 1.838-.371 1.838-1.089 0-.817-.543-1.25-2.062-1.523l-1.893-.359c-2.549-.458-3.932-1.95-3.932-4.042 0-2.8 2.254-4.414 5.7-4.414 3.41 0 5.021 1.405 5.881 2.984L126.863 9.3a3.1 3.1 0 0 0-3.008-1.863c-1.187 0-1.82.427-1.82 1.133 0 .675.516 1.059 1.82 1.306l1.92.359c2.84.514 4.227 1.678 4.227 4.2 0 2.377-1.521 4.531-5.99 4.531-3.3 0-5.547-1.263-6.559-3.2Z"
      ></path>
    </svg>
  </a>
</template>
