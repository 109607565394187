import { createRouter, createWebHistory } from 'vue-router'
import { getViewer, loaded } from '@/viewer.js'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...setupLayouts(generatedRoutes),
    { path: '/', redirect: '/guided-shopping/new' },
    { path: '/guided-shopping', redirect: '/guided-shopping/new' },
    { path: '/guided-shopping/basic', redirect: '/guided-shopping/new' },
    { path: '/guided-shopping/basic/how-many-employees', redirect: '/guided-shopping/new' },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export async function beforeEach(to, from, params) {
  const { getViewer, loaded } = params

  if (to.path === '/getstarted' && !loaded.value) {
    await getViewer()
    return
  }

  // handle trailing slashes
  if (to.path !== '/' && to.path.endsWith('/')) {
    return { path: to.path.slice(0, -1), query: to.query, hash: to.hash, replace: true }
  }

  // handle external payroll dashboard
  if (to.path === '/dashboard') {
    window.location.href = `${import.meta.env.VITE_APP_PAYROLL_DASHBOARD_BASE_URL}${to.fullPath}`
    return false
  }
}

router.beforeEach((to, from) =>
  beforeEach(to, from, {
    getViewer,
    loaded,
  })
)

export default router
